<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-transition"
    content-class="ma-1"
    max-width="500px"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3 color3Text--text"
        dark
        v-on="on"
        :fab="!text"
        small
        :text="text"
      >
        <v-icon>fas fa-users</v-icon>
        <span v-if="text" class="pl-3">Preview the players</span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Players</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          fab small @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="loading" v-if="loading"></loading-bar>
      <v-window v-model="window">
        <v-window-item
          v-for="(player, i) in displayPlayers"
          :key="player.ppid"
        >
          <v-card v-if="player.loading">
            <v-responsive :aspect-ratio="1">
              <v-skeleton-loader
                type="image"
                height="100%"
              ></v-skeleton-loader>
            </v-responsive>
          </v-card>
          <profile-card
            v-else
            :player="player"
            size="100%"
            :key="player.ppid"
            :active="window === i"
          ></profile-card>
        </v-window-item>
      </v-window>
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="displayPlayers.length"
          circle
          prev-icon="fas fa-caret-left"
          next-icon="fas fa-caret-right"
        ></v-pagination>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ProfileCard from '@/components/Player/ProfileCard'
import PlayerProfile from '@/classes/PlayerProfile'
import { mapGetters } from 'vuex'
import flatten from '@/helpers/ArrayFlatten'

export default {
  props: ['playerIds', 'match', 'text'],
  data () {
    return {
      dialog: false,
      window: null,
      loading: false,
      players: [],
      startId: null,
      matchPIds: []
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getTeam']),
    pids () {
      return this.match ? this.matchPIds : this.playerIds
    },
    needed () {
      return this.pids ? this.pids.filter(id => this.players.findIndex(p => p.id === id) === -1) : []
    },
    displayPlayers () {
      if (this.loading && !this.players.length) {
        return [{ id: -1, loading: true }]
      }
      return this.players.filter(p => this.pids.findIndex(id => id === p.id) > -1)
    },
    page: {
      get () {
        return this.window + 1
      },
      set (val) {
        this.window = val - 1
      }
    }
  },
  methods: {
    getPlayerIds () {
      if (this.match) {
        this.matchPIds = flatten(this.match.activeTeamIds.map(m => {
          var t = this.getTeam(m)
          return t && t.players.map(p => p.playerProfileId)
        }).filter(f => !!f))
      }
    },
    getPlayers () {
      this.loading = true
      this.getPlayerIds()
      this.$VBL.get.playerList(null, { ids: this.needed })
        .then(r => {
          const data = r.data.players
          data.forEach(m => {
            var x = this.players.find(f => f.id === m.id)
            if (!x) {
              this.players.push(new PlayerProfile(null, m))
            }
          })
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    open (ppid) {
      this.startId = ppid
      this.dialog = true
    },
    goto (ppid) {
      const i = this.displayPlayers.findIndex(f => f.ppid === ppid)
      if (i > -1) this.window = i
    }
  },
  watch: {
    dialog: function (val) {
      if (val && (this.needed.length || this.match)) {
        this.getPlayers()
      }
    }
  },
  components: {
    ProfileCard
  }
}
</script>
<style scoped>
.v-dialog {
  margin: 4px !important;
}
</style>
